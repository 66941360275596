<template>
  <div :class="['relative']">
    <i
      v-if="loading"
      class="fal fa-spinner-third animate-spin absolute text-3xl top-1/2 left-1/2"
    />
    <DatePicker
      v-model="date"
      v-bind="$attrs"
      name="visitDate"
      :language="calendarLocale[$i18n.locale]"
      :full-month-name="true"
      :monday-first="true"
      :disabled-dates="disabledDates"
      :class="{ 'opacity-40 pointer-events-none': loading }"
      v-on="$listeners"
    >
      <span slot="prevIntervalBtn">
        <i class="far fa-chevron-left w-full text-right" />
      </span>
      <span slot="nextIntervalBtn">
        <i class="far fa-chevron-right" />
      </span>
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker';
import { nl, fr, en } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'Calendar',
  components: {
    DatePicker
  },
  // To remove any direct inheritance to root element magic, inheritAttrs: false and v-bind="$attrs" ensures that the attributes get bound to the right element.
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Date
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      calendarLocale: {
        nl,
        en,
        fr
      },
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)) // All dates before today
      }
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  }
};
</script>

<style scoped>
@import '~@/styles/calendar.css';
</style>
